.menu {
  position: relative;
  z-index: 50;
  display: flex;
  min-width: 180px;
  flex-direction: column;
  overscroll-behavior: contain;
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: hsl(204 20% 88%);
  background-color: hsl(204 20% 100%);
  padding: 0.5rem;
  color: hsl(204 10% 10%);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  outline: none !important;
  max-height: max-content;
  overflow: visible;
  --menu-origin-inline: calc(var(--popover-anchor-width) / 2);
}

.menu[data-placement^="bottom"] {
  transform-origin: var(--menu-origin-inline) -11px;
}

.menu[data-placement^="top"] {
  transform-origin: var(--menu-origin-inline) calc(100% + 11px);
}
